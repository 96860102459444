import { IRootState } from 'config/store';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DataTable from 'shared/widgets/dataTable';
import { IDevice } from 'shared/model/device.model';
import { IDataTableColumn } from 'react-data-table-component';
import { Alert } from '@material-ui/lab';
import validateRequired from 'shared/widgets/form/validateRequired';
import combineValidates from 'shared/widgets/form/combineValidates';
import { GroupAutoOrder } from 'shared/model/autoOrder.model';
import { AutoOrderFormResponse } from './AutoOrderForm';
import { usePrevious } from 'shared/utils/react-utils';
import StepContainer from 'shared/widgets/form/StepContainer';
import EditDeviceContentDialog from './EditDeviceContentDialog';
import { Edit } from '@material-ui/icons';
import { fetchProducts } from 'shared/reducers/productSlice';
import { IProduct } from 'shared/model/product.model';
import useProductDialog from 'modules/products/dialogs/useProductDialog';
import { fetchDevices, updateDevicesWithGroup } from 'shared/reducers/devicesSlice';

const AutoOrderFormSilosSelect = ({
  isNew,
  editGroupAutoOrder,
  isActiveStep
}: {
  isNew: boolean;
  editGroupAutoOrder?: GroupAutoOrder;
  isActiveStep: boolean;
}) => {
  const { t } = useTranslation();
  const { setValue, register, watch, errors, triggerValidation } =
    useFormContext<AutoOrderFormResponse>();
  const groupId = watch('groupId')?.value;
  const shipTo = watch('shipTo');
  const soldTo = watch('soldTo');
  const dispatch = useDispatch();
  const group = useSelector(({ group }: IRootState) =>
    group.groups.find(g => g.group_id === groupId)
  );
  const products = useSelector(({ product }: IRootState) => product.products);
  const [deviceToUpdate, setDeviceToUpdate] = useState<IDevice>();
  const [productToUpdate, setProductToUpdate] = useState<IProduct>();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const allDevices = useSelector(({ devices }: IRootState) => devices.devices);
  const devices = useMemo(
    () => allDevices.filter(d => d.farm_id === groupId),
    [groupId, allDevices]
  );
  const selectedDevices = devices.filter(d => selectedDeviceIds.includes(d.device_id));
  const isMissingDeviceContents = !selectedDevices.every(
    device => device.deviceContent?.device_content && device.deviceContent?.device_content_reference
  );

  const existingAutoOrderDeviceIds = editGroupAutoOrder
    ? editGroupAutoOrder.device_auto_orders.map(deviceAutoOrder => deviceAutoOrder.device_id)
    : [];

  // We want to select all rows by default, but depend on the callback from
  // react-data-table-component. So we do it only on the first load and
  // keep track of it.
  const shouldProvideDefaultSelection = useRef(true);
  const previousGroupId = usePrevious(groupId);
  if (previousGroupId !== groupId) {
    // When the group changed, we want to reselect all rows by default
    shouldProvideDefaultSelection.current = true;
  }

  // Register device selection to form
  useEffect(() => {
    register('selectedDeviceIds', {
      validate: combineValidates(
        isActiveStep && validateRequired,
        isActiveStep &&
          (() => {
            return !isMissingDeviceContents || t('missing_infos_selected_silos');
          })
      )
    });
  }, [isActiveStep, isMissingDeviceContents, register, t]);

  // Sync device selection to form
  useEffect(() => {
    setValue('selectedDeviceIds', selectedDeviceIds);
    triggerValidation();
  }, [selectedDeviceIds, setValue, triggerValidation]);

  // Allow editing product number
  useEffect(() => {
    (async () => {
      dispatch(fetchProducts());
      await dispatch(fetchDevices());
      dispatch(updateDevicesWithGroup());
    })();
  }, [dispatch]);

  const { dialog: productDialog, openDialog: openProductDialog } = useProductDialog({
    product: productToUpdate,
    onlyEditReferences: true,
    onSuccess: async () => {
      await dispatch(fetchDevices());
      await dispatch(updateDevicesWithGroup());
      triggerValidation();
    }
  });

  const columns: IDataTableColumn<IDevice>[] = useMemo(() => {
    return [
      {
        name: t('silo_plural'),
        id: 'device_name',
        selector: row => row.device_name,
        grow: 2
      },
      {
        selector: row => row.capa_max,
        name: t('capacity'),
        sortable: true,
        format: row => t('number_workspace_filling_unit', { value: row.capa_max }),
        grow: 1
      },
      {
        name: t('device_content'),
        selector: 'device-content',
        format: row => {
          const deviceContent = row.deviceContent?.device_content ?? '';
          const isSelected = selectedDeviceIds.includes(row.device_id);
          if (isSelected && !deviceContent) {
            return (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                size="small"
                color="secondary"
                onClick={() => setDeviceToUpdate(row)}
              >
                <span style={{ textTransform: 'none' }}>{t('add_content')}</span>
              </Button>
            );
          } else {
            return deviceContent;
          }
        },
        sortable: true,
        grow: 3
      },
      {
        name: t('reference'),
        selector: 'device-content-reference',
        format: row => {
          const deviceContent = row.deviceContent?.device_content ?? '';
          const deviceContentReference = row.deviceContent?.device_content_reference ?? '';
          const isSelected = selectedDeviceIds.includes(row.device_id);
          if (isSelected && deviceContent && !deviceContentReference) {
            return (
              <Button
                variant="outlined"
                startIcon={<Edit />}
                size="small"
                color="secondary"
                onClick={() => {
                  const product = products.find(p => p.id === row.device_content_id);
                  if (product) {
                    setProductToUpdate(product);
                    openProductDialog(true);
                  }
                }}
              >
                <span style={{ textTransform: 'none' }}>{t('enter_content_reference')}</span>
              </Button>
            );
          } else {
            return deviceContentReference;
          }
        },
        sortable: true,
        grow: 1.5
      }
    ];
  }, [t, selectedDeviceIds, products, openProductDialog]);

  return (
    <StepContainer isActiveStep={isActiveStep}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <TextField
            label={t('plant')}
            value={group?.group_name ?? ''}
            disabled
            placeholder="None"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item>
          <TextField
            label={t('ship_to')}
            value={shipTo || ''}
            disabled
            InputLabelProps={{ shrink: true }}
            placeholder="None"
          />
        </Grid>

        <Grid item>
          <TextField
            label={t('sold_to')}
            value={soldTo || ''}
            disabled
            placeholder="None"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Box p={1}>
        <DataTable
          key={groupId} // Remount the table when the group changes, to reselect all rows by default
          noHeader
          selectableRows
          onSelectedRowsChange={state => {
            setSelectedDeviceIds(state.selectedRows.map(row => row.device_id));
          }}
          selectableRowSelected={row => {
            if (shouldProvideDefaultSelection.current === true) {
              // Hack: after this callback was called for each row, and the
              // selection is done, set the flag back to false.
              setTimeout(() => {
                shouldProvideDefaultSelection.current = false;
              }, 0);

              if (!isNew) {
                return existingAutoOrderDeviceIds.includes(row.device_id);
              } else {
                // Select all rows by default
                return true;
              }
            } else {
              // Issue: react-data-table-component loses track of selected rows
              // when underlying data changes (after a devices update). We need to restore it
              return selectedDeviceIds.includes(row.device_id);
            }
          }}
          columns={columns}
          data={devices}
          defaultSortField="device_name"
          defaultSortAsc={true}
          paginationPerPage={20}
        />
        {errors.selectedDeviceIds && (
          <Alert severity="error">{errors.selectedDeviceIds.message}</Alert>
        )}
      </Box>

      <EditDeviceContentDialog
        device={deviceToUpdate}
        onClose={() => setDeviceToUpdate(undefined)}
        onUpdated={() => {
          setDeviceToUpdate(undefined);
          triggerValidation();
        }}
      />

      {productDialog}
    </StepContainer>
  );
};

export default AutoOrderFormSilosSelect;
