import React, { ComponentProps, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { createProduct, updateProduct } from 'shared/reducers/productSlice';
import { IProduct } from 'shared/model/product.model';
import ProductForm, { IProductFormResponse } from './ProductForm';
import Loading from 'shared/widgets/loading';

function useProductDialog({
  product,
  onSuccess = () => {},
  onCancel = () => {},
  onlyEditReferences
}: Pick<ComponentProps<typeof ProductForm>, 'onlyEditReferences'> & {
  product?: IProduct;
  onSuccess?: () => void;
  onCancel?: () => void;
}) {
  const isNew = !product?.id;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const openDialog = useCallback((shouldBeOpen: boolean = true) => {
    setOpen(shouldBeOpen);
  }, []);

  const handleSubmit = async (values: IProductFormResponse) => {
    setIsLoading(true);
    const payload = {
      device_content: values.device_content?.trim(),
      device_content_reference: values.device_content_reference?.trim(),
      contract_number: values.contract_number?.trim(),
      group_ids: values?.groups?.map(g => g.value)
    };
    if (isNew) {
      await dispatch(createProduct({ ...payload }));
    } else {
      await dispatch(updateProduct({ ...payload, id: product.id }));
    }

    onSuccess();
    setIsLoading(false);
    setOpen(false);
  };

  const dialog = (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        setOpen(false);
        onCancel();
      }}
    >
      {isLoading ? (
        <Box p={4}>
          <Loading />
        </Box>
      ) : (
        <ProductForm
          product={product}
          onlyEditReferences={onlyEditReferences}
          onSubmit={handleSubmit}
          onCancel={() => {
            setOpen(false);
            onCancel();
          }}
        />
      )}
    </Dialog>
  );

  return {
    dialog,
    openDialog
  };
}

export default useProductDialog;
