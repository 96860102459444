import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IAlert, IAlertNotification } from 'shared/model/alert.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { convertDateFromServer, convertDatesToServer } from 'shared/utils/date-utils';
import { errorNotification } from './notifierSlice';

interface IAlertsResponse {
  results: IAlert[];
}

export interface IAlertNotificationsResponse {
  count: number;
  results: IAlertNotification[];
}

const initialState = {
  loading: false,
  loadingNotifications: false,
  errorMessage: '',
  alerts: [] as IAlert[],
  notifications: [] as IAlertNotification[],
  alert: null as IAlert | null,
  updating: false,
  updateSuccess: false
};

export type AlertState = typeof initialState;

export const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    fetchAlertsStart: state => {
      state.loading = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    fetchAlertsFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    fetchAlertsSuccess: (state, action: PayloadAction<IAlert[]>) => {
      action.payload.forEach(alert => {
        alert.last_checked_timestamp = convertDateFromServer(alert.last_checked_timestamp);
        alert.last_modified_timestamp = convertDateFromServer(alert.last_modified_timestamp);
        alert.last_triggered_timestamp = convertDateFromServer(alert.last_triggered_timestamp);
      });
      state.loading = false;
      state.alerts = action.payload;
    },
    fetchAlertSuccess: (state, action: PayloadAction<IAlert>) => {
      const alert = action.payload;
      alert.last_checked_timestamp = convertDateFromServer(alert.last_checked_timestamp);
      alert.last_modified_timestamp = convertDateFromServer(alert.last_modified_timestamp);
      alert.last_triggered_timestamp = convertDateFromServer(alert.last_triggered_timestamp);
      state.loading = false;
      state.alert = alert;
    },
    updateAlertStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateAlertFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateAlertSuccess: (state, action: PayloadAction<IAlert>) => {
      state.updating = false;
      state.updateSuccess = true;
      state.alert = action.payload;
    },
    deleteAlertSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
      state.alert = null;
    },
    keepAlert: (state, action: PayloadAction<IAlert>) => {
      state.alert = action.payload;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchAlertsStart,
  fetchAlertsFailed,
  fetchAlertsSuccess,
  updateAlertStart,
  updateAlertFailed,
  updateAlertSuccess,
  deleteAlertSuccess,
  fetchAlertSuccess
} = slice.actions;

export const { keepAlert } = slice.actions;

const apiUrl = getEnvApiUrl();

export const fetchAlerts = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchAlertsStart());
    const response: AxiosResponse<IAlertsResponse> = await axios.get(
      `${apiUrl}/alerts?excludeAutoOrders=true`
    );
    dispatch(fetchAlertsSuccess(response.data.results));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchAlertsFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const fetchAlert =
  (id: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(fetchAlertsStart());
      const response: AxiosResponse<IAlert> = await axios.get(`${apiUrl}/alerts/${id}/`);
      dispatch(fetchAlertSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(fetchAlertsFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const createAlert =
  (alert: IAlert): AppThunk =>
  async dispatch => {
    try {
      const toSend = { ...alert };
      convertDatesToServer(toSend);

      dispatch(updateAlertStart());
      const response: AxiosResponse = await axios.post(`${apiUrl}/alerts/`, toSend);
      dispatch(updateAlertSuccess(response.data));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateAlertFailed(error.message));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const updateAlert =
  (alert: Partial<IAlert>, fetch: boolean = false): AppThunk =>
  async dispatch => {
    try {
      const toSend = { ...alert };
      convertDatesToServer(toSend);
      delete toSend.alert_id;
      dispatch(updateAlertStart());
      const response: AxiosResponse = await axios.put(
        `${apiUrl}/alerts/${alert.alert_id}/`,
        toSend
      );
      dispatch(updateAlertSuccess(response.data));

      if (fetch) dispatch(fetchAlerts());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateAlertFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const deleteAlert =
  (alert: IAlert): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateAlertStart());
      await axios.delete(`${apiUrl}/alerts/${alert.alert_id}`);
      dispatch(deleteAlertSuccess());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateAlertFailed(error.message));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };
