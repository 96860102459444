import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IDevice } from 'shared/model/device.model';
import { useFormContext } from 'react-hook-form';
import { AutoOrderFormResponse } from '../AutoOrderForm';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'config/store';
import { sortDeviceAutoOrders } from 'shared/model/autoOrder.model';
import { isEqual } from 'lodash';
import { workspaceTruckQuantity } from 'shared/utils/workspace-utils';

const MAX_TRIGGERS = 10;
const SAFETY_MARGIN = 0.9;

/*

- Initialement le switch doit etre actif si la configuration actuelle est la meme que la configuration suggérée
- Activer le switch doit appliquer la configuration suggérée
- Désactiver le switch doit conserver la configuration actuelle et permettre l'édition manuelle
- Ajouter des devices doit dans tous les cas appliquer une configuration suggérée

*/

function AutoSettingsButton({
  devices,
  isNew
}: {
  devices: IDevice[];
  // Whether we are creating an auto-order from scratch
  isNew: boolean;
}) {
  const { t } = useTranslation();
  const [isWarningOpen, setWarningOpen] = useState(false);
  const { watch, register, setValue } = useFormContext<AutoOrderFormResponse>();
  const useAutoSettings = watch('useAutoSettings');
  const autoOrders = watch('autoOrders');
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const truckQuantity = workspaceTruckQuantity(settings);

  const autoConfig = useMemo(
    () => applyAutoOrdersConfig(autoOrders, suggestedAutoOrders(devices, truckQuantity)),
    [autoOrders, devices, truckQuantity]
  );

  const isInitialized = useRef(false);
  useEffect(() => {
    const initialValue =
      isNew || isSameConfig(autoOrders, applyAutoOrdersConfig(autoOrders, autoConfig));

    register('useAutoSettings');
    setValue('useAutoSettings', initialValue);
    isInitialized.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Keep the suggested settings up to date
  useEffect(() => {
    if (!isInitialized.current) return;

    if (useAutoSettings && !isSameConfig(autoOrders, autoConfig)) {
      setValue('autoOrders', autoConfig);
    }
  }, [autoOrders, setValue, autoConfig, useAutoSettings]);

  return (
    <>
      <FormControlLabel
        label={t('auto_settings')}
        control={
          <Switch
            color="primary"
            checked={useAutoSettings ?? true}
            onChange={(_event, checked) => {
              if (checked) {
                setValue('useAutoSettings', checked);
              } else {
                setWarningOpen(true);
              }
            }}
          />
        }
      />
      <Dialog open={isWarningOpen}>
        <DialogTitle>{t('auto_settings')}</DialogTitle>

        <DialogContent>
          <DialogContentText>{t('auto_settings_explanation')}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setWarningOpen(false);
              setValue('useAutoSettings', false);
            }}
          >
            {t('configure_manually')}
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={() => {
              setWarningOpen(false);
            }}
          >
            {t('keep_auto_settings')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function suggestedAutoOrders(
  devices: IDevice[],
  truckQuantity: number,
  generateId: () => string = uuid // used for unit tests
): AutoOrderFormResponse['autoOrders'] {
  const autoOrders = devices.reduce((acc, device) => {
    const triggerValues = suggestedMissingSpaceTriggerValues(device.capa_max, truckQuantity);

    acc[device.device_id] = triggerValues.map(triggerValue => ({
      alert_name: `AUTO-ORDER (DEFAULT) ${device.device_name}`,
      alert_id: generateId(),
      data_type: 'missingWeight',
      max_value: triggerValue,
      is_active: true,
      custom_integration: {
        nbOfOrder: 1
      }
    }));

    return acc;
  }, {} as AutoOrderFormResponse['autoOrders']);

  return autoOrders;
}

/**
 * Example: suggestedMissingSpaceTriggerValues(100, 31) => [31, 62, 93]
 */
export function suggestedMissingSpaceTriggerValues(
  maximumCapacity: number,
  truckQuantity: number
): number[] {
  // Calculate the number of truck that can fit in the device
  const triggerCount = Math.min(
    Math.floor((maximumCapacity * SAFETY_MARGIN) / truckQuantity),
    MAX_TRIGGERS
  );
  // Array [1, 2, ..., triggerCount]
  const triggers = Array.from({ length: triggerCount }, (_, i) => i + 1);

  return triggers.map(trigger => truckQuantity * trigger);
}

function isSameConfig(
  config1: AutoOrderFormResponse['autoOrders'],
  config2: AutoOrderFormResponse['autoOrders']
): boolean {
  // Make sure sorting is not a issue
  function sortConfig(
    config: AutoOrderFormResponse['autoOrders']
  ): AutoOrderFormResponse['autoOrders'] {
    return Object.entries(config).reduce((acc, [deviceId, autoOrders]) => {
      return {
        ...acc,
        [deviceId]: sortDeviceAutoOrders(autoOrders, 100000)
      };
    }, {} as AutoOrderFormResponse['autoOrders']);
  }

  return isEqual(sortConfig(config1), sortConfig(config2));
}

/**
 * Apply a default configuration to an existing configuration. Tries to preserve existing alerts
 */
export function applyAutoOrdersConfig(
  currentConfig: AutoOrderFormResponse['autoOrders'],
  newConfig: AutoOrderFormResponse['autoOrders']
): AutoOrderFormResponse['autoOrders'] {
  return Object.entries(newConfig).reduce((acc, [newDeviceId, newDeviceAutoOrders]) => {
    const currentDeviceAutoOrders = sortDeviceAutoOrders(
      currentConfig[newDeviceId] ?? [],
      100000 // We don't care about sorting level_t alerts, just the missingWeight ones
    );

    const isDeviceActive = currentDeviceAutoOrders.every(autoOrder => autoOrder.is_active);

    return {
      ...acc,
      [newDeviceId]: newDeviceAutoOrders.map(newAutoOrder => {
        // Is there an auto-order with the right configuration already ?
        const currentAutoOrder = currentDeviceAutoOrders.find(
          autoOrder =>
            autoOrder.max_value === newAutoOrder.max_value &&
            autoOrder.data_type === newAutoOrder.data_type &&
            autoOrder.custom_integration.nbOfOrder === newAutoOrder.custom_integration.nbOfOrder
        );
        return {
          ...(currentAutoOrder ?? newAutoOrder),
          is_active: isDeviceActive
        };
      })
    };
  }, {} as AutoOrderFormResponse['autoOrders']);
}

export default AutoSettingsButton;
